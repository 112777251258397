import React, {Component, useEffect, useState} from 'react';
import ReactDOM from 'react-dom';
import Modal from './Modal.jsx';
import "./Personal/assets/css/style.css";
import "./Personal/assets/vendor/bootstrap/css/bootstrap.min.css" ;
import "./Personal/assets/vendor/bootstrap-icons/bootstrap-icons.css" ;
import "./Personal/assets/vendor/boxicons/css/boxicons.min.css" ;
import "./Personal/assets/vendor/glightbox/css/glightbox.min.css" ;
import "./Personal/assets/vendor/remixicon/remixicon.css" ;
import "./Personal/assets/vendor/swiper/swiper-bundle.min.css" ;




export default function Home(props) {
  
  const [modalOpen, setModalOpen] = useState(false);

  
  const openModal = () => {
    setModalOpen(true);
  };
  const closeModal = () => {
    setModalOpen(false);
  };
    return(
        <header id="header">
          <div class="container">
      
            <h1><a href="index.html">CRAFTER721</a></h1>
            <a href="index.html" class="mr-auto"><img src="assets/img/logo.png" alt="" class="img-fluid"/></a>
            <h2>The most powerful <span>NFT ecosystem</span></h2>
            <span style={{color: "white", fontSize: "18px"}}>...And it's for FREE!</span><br/>

            
            <h3 style={{color: "white", fontSize: "16px", marginTop: "15px"}}>- NO CODE for smart contract build and deploy</h3>
            <h3 style={{color: "white", fontSize: "16px"}}>- NFT smart contract on Ethereum, Klaytn(will add more blockchain)</h3>
            <h3 style={{color: "white", fontSize: "16px"}}>- Set Max Supply, Purchase Limit, and White List with just Click</h3>
            <h3 style={{color: "white", fontSize: "16px"}}>- Manage your minted NFTs with snapshot function</h3>
            <h3 style={{color: "white", fontSize: "16px"}}>- NFT mint for team and airdrop function</h3>
            <h3 style={{color: "white", fontSize: "18px"}}>- ...and many things for FREE!</h3>
      
            <nav style={{paddingBottom: "0px"}} id="navbar" class="navbar">
              <ul>
                {/* <li><button type="submit"><a class="nav-link active" href="https://crafter721.s3.ap-northeast-2.amazonaws.com/CRAFTER721+Setup+0.1.0.exe">Download Beta for Windows</a></button></li> */}
                <li><button type="submit" onClick={openModal}>Download Beta for Windows</button></li>
                <li><button type="submit">MacOS user Wait until I get Macbook</button></li>
              </ul><br/>
            </nav>
            <nav style={{paddingBottom: "0px", marginTop: "0px"}} id="navbar" class="navbar">
              <ul>
              <li><button type="submit"><a class="nav-link" href="https://docs.crafter721.com/">Explore Guide</a></button></li> 
              <li><button type="submit"><a class="nav-link" href="https://kr-docs.crafter721.com/">Explore KR-Guide</a></button></li> 
              </ul>
            </nav>
      
            <div class="social-links">
              <a href="https://discord.gg/77JBtm29kp" class="discord"><i class="bi bi-discord"></i></a>
              <a href="https://medium.com/@eklee808" class="medium"><i class="bi bi-medium"></i></a>
            </div>
      
          </div>
        {modalOpen ?
          <>
          <Modal open={openModal} close={closeModal}
          ></Modal></>
          :
          <></>
        }
        </header>
    );

}