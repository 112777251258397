import react, {Component, useEffect, useState} from 'react';
import './Modal.css';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
const useStyles = makeStyles({
    biteModalSection: {
        borderRadius: "20px",
        margin: "0px",
        backgroundSize: "cover",
        backgroundColor: "transparent",
        border: "1px solid white"
    },
  biteModal: {
    minWidth: "453px",
    minHeight: "301px",
    margin: "0px",
    backgroundSize: "cover",
    backgroundColor: "transparent",
  },
  kacButton: {
    minWidth: "100px",
    minHeight: "35px",
    marginTop: "43%",
    marginRight: "15px",
    backgroundSize: "cover",
    backgroundColor: "transparent"

  },
  ztButton: {
    minWidth: "100px",
    minHeight: "35px",
    marginTop: "43%",
    backgroundSize: "cover",
    backgroundColor: "transparent"

  },
  buttonDisabled: {
    minWidth: "100px",
    minHeight: "35px",
    marginTop: "43%",
    backgroundSize: "cover",
    backgroundColor: "gray"
  },
  closeButton: {
    minWidth: "30px",
    minHeight: "30px",
    margin: "0px",
    marginTop: "5px",
    marginRight: "-15px",
    float: "right",
    backgroundSize: "cover",
    backgroundColor: "transparent"

  }
});

export default function ApproveModal(props) {
    const classes = useStyles();
  // 열기, 닫기, 모달 헤더 텍스트를 부모로부터 받아옴
  const { open, close, tokenData } = props;


  return (
    // 모달이 열릴때 openModal 클래스가 생성된다.
    <div className={open ? 'openModal modal' : 'modal'} style={{left: "30%", top:"30%",border:"0px solid", width: "50%", height: "40%",alignItems: "center", justifyContent:"center"}}>
      {open ? 
        <div style={{height: "100%"}}>
          <CardContent>
          <Typography variant="h5" component="div">
          Term of Use
          </Typography>
          <Typography variant="body2">
            <br/>Please read these terms of service (“terms”, “terms of service”) carefully before using Crafter721 website (the “service”) operated by us.
            <br/>
            <br/>Conditions of Use
            <br/>
            <br/>We will provide their services to you, which are subject to the conditions stated below in this document. Every time you use Crafter721, use its services or make a purchase, you accept the following conditions. This is why we urge you to read them carefully.
            <br/>
            <br/>Privacy Policy
            <br/>
            <br/>Before you continue using Crafter721 we advise you to read our privacy policy that "We don't collect any of your private information except name and contract address of NFT conllections you make via Crafter721"
            <br/>
            <br/>Copyright
            <br/>
            <br/>Content published on this website and Crafter721 program (digital downloads, images, texts, graphics, logos) is the property of Eunkang Lee and protected by international copyright laws. The entire compilation of the content found on this website is the exclusive property of Eunkang Lee, with copyright authorship for this compilation by Eunkang Lee.
            <br/>
            <br/>License and Site Access
            <br/>
            <br/>We grant you a limited license to access and make personal use of this website. You are not allowed to download or modify it. This may be done only with written consent from us.
            <br/>
            <br/>User Account
            <br/>
            <br/>If you are an owner of an account on this website, you are solely responsible for maintaining the confidentiality of your private user details (username and password). You are responsible for all activities that occur under your account or password.
            <br/>
            <br/>We reserve all rights to terminate accounts, edit or remove content and cancel orders at their sole discretion.
          </Typography>
          </CardContent>
          <CardActions>
          <Button disabled={props.approve1} className={classes.kacButton} onClick={close}><a class="nav-link active" href="https://crafter721.s3.ap-northeast-2.amazonaws.com/CRAFTER721+Setup+0.1.0.exe">I Agree</a></Button>
          <Button disabled={props.approve2} className={classes.ztButton} onClick={close}>Close</Button>
          </CardActions>
        </div>
      : null}
    </div>
  );
};